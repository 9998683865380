import { useCallback, useEffect, useState } from "react";
import "../styles/milei.css";
import click from "../sounds/click.mp3";
import cuatro from "../sounds/cuatro.mp3";
import distinta from "../sounds/distinta.mp3";
import hastaAca from "../sounds/hastaAca.mp3";
import okay from "../sounds/okay.mp3";

const allSounds = [distinta, cuatro, hastaAca, okay];

const sonidoFuncion = () => {
  const randomIndex = Math.floor(Math.random() * allSounds.length);
  const randomSound = allSounds[randomIndex];
  new Audio(randomSound).play();
};
// setInterval(sonidoFuncion, 20000);

function Milei({ clicks, setClicks, dpc }) {
  const clicker = () => {
    setClicks(clicks + dpc);
    new Audio(click).play();
  };
  return (
    <div className="img__container">
      <img
        src={require(`../images/milei.png`)}
        className="milei__image"
        onClick={clicker}
        alt=""
      />
    </div>
  );
}

export default Milei;
