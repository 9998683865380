import { useState } from "react";
import "../styles/purchases.css";
import purchase from "../sounds/purchase.mp3";

function Purchase({
  image,
  name,
  purchasePrice,
  second,
  clicks,
  setClicks,
  dps,
  setDps,
}) {
  let [price, setPrice] = useState(purchasePrice);
  let [secondByPurchase, setSecond] = useState(second);
  let [quanity, setQuanity] = useState(0);
  let quanityToSecond = 0;

  const purchaseDone = () => {
    if (clicks >= price) {
      let roundedPrice = Math.floor(price * 1.2);
      setPrice(roundedPrice);

      let roundedDps = (dps += second).toFixed(1);
      setDps(parseFloat(roundedDps));

      setClicks(clicks - price);

      setQuanity((quanity += 1));
      quanityToSecond += 1;

      new Audio(purchase).play();
    }
    if (quanityToSecond >= 10) {
      let priceMultiplied = secondByPurchase * 2;
      setSecond(priceMultiplied);
      quanityToSecond -= 10;
    }
  };

  return (
    <div
      className={
        clicks >= price ? "purchase__container can" : "purchase__container cant"
      }
      onClick={purchaseDone}
    >
      <div className="purchase__img--container">
        <img
          src={require(`../images/purchase-${image}.png`)}
          className="purchase__img"
          alt=""
        />
      </div>
      <div className="text__container--purchase">
        <h1 className="name">{name}</h1>
        <h2 className="price">${price}</h2>
        <h2 className="price">+{secondByPurchase} DPS</h2>
      </div>
      <div className="quanity">
        <p>
          <b className="quanity">{quanity}</b>
        </p>
      </div>
    </div>
  );
}

export default Purchase;
