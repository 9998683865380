import "./App.css";
import { useState, useEffect } from "react";
import Purchase from "./components/purchase";
import Counter from "./components/header";
import Milei from "./components/milei";
import "./styles/upgrades.css";
import afuera from "./sounds/afuera.mp3";

const initialUpgrades = [
  { image: "voucher", plus: "+", price: "800", add: "25" },
  { image: "banco", plus: "+", price: "10000", add: "69" },
  { image: "dolarizacion", plus: "+", price: "40000", add: "110" },
  { image: "desaparecidos", plus: "+", price: "130000", add: "730" },
  { image: "motosierra", plus: "+", price: "250000", add: "1700" },
  { image: "pala", plus: "+", price: "1000000", add: "7500" },
  { image: "perro", plus: "+", price: "5000000", add: "13000" },
  { image: "pochita", plus: "+", price: "12000000", add: "50000" },
  { image: "atun", plus: "+", price: "100000000", add: "200000" },
  { image: "campora", plus: "+", price: "875300000", add: "875300" },
  { image: "fideos", plus: "+", price: "1200000000", add: "4000000" },
  { image: "tuco", plus: "+", price: "10000000000", add: "10000000" },
  { image: "fmi", plus: "+", price: "25000000000", add: "23000000" },
  { image: "montoneros", plus: "+", price: "100000000000", add: "87530000" },
  { image: "afip", plus: "+", price: "1000000000000", add: "250000000" },
  { image: "massa", plus: "+", price: "999999999999999", add: "-1" },
];

function App() {
  let [clicks, setClicks] = useState(0);
  let [dps, setDps] = useState(0);
  let [dpc, setDpc] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      clicks += parseFloat(dps / 10);
      setClicks(parseInt(clicks));
    }, 100);

    return () => clearInterval(interval);
  }, [clicks, setClicks]);

  const [upgrades, setUpgrades] = useState(initialUpgrades);

  const removeUpgrade = (index) => {
    if (clicks >= upgrades[index].price) {
      setClicks((clicks -= upgrades[index].price));
      setDpc((dpc += parseInt(upgrades[index].add)));
      const updated = [...upgrades];
      updated.splice(index, 1);
      setUpgrades(updated);
      new Audio(afuera).play();
    }
  };

  const Upgrades = upgrades.map(
    ({ image, plus, price, clicks, add }, index) => (
      <div key={index}>
        <div
          onClick={() => removeUpgrade(index)}
          className={clicks >= price ? "upgrade can-up" : "upgrade cant-up"}
        >
          <img
            src={require(`./images/purchase-${image}.png`)}
            className="upgrade__image"
          />
          <div className="text">
            <h2>{price}</h2>
            <h3 className="plus">+{add} DPC</h3>
          </div>
        </div>
      </div>
    )
  );

  return (
    <div className="App">
      <div className="main__container">
        <div className="header">
          <Counter
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
            dpc={dpc}
            setDpc={setDpc}
          />
        </div>
        <div className="purchases">
          <Purchase
            image="voucher"
            name="Voucher"
            purchasePrice="50"
            second={1}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="banco"
            name="Banco Central"
            purchasePrice="100"
            second={2}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="dolarizacion"
            name="Dolarizacion"
            purchasePrice="1200"
            second={8}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="desaparecidos"
            name="???"
            purchasePrice="8753"
            second={16}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="motosierra"
            name="Motosierra"
            purchasePrice="56000"
            second={86}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="pala"
            name="Pala"
            purchasePrice="200000"
            second={250}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="perro"
            name="Milton"
            purchasePrice="1000000"
            second={1400}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="pochita"
            name="Pochita"
            purchasePrice="20000000"
            second={8000}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="atun"
            name="Lata de Atún"
            purchasePrice="330000000"
            second={45000}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="campora"
            name="La Cámpora"
            purchasePrice="5100000000"
            second={250000}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="fideos"
            name="Fideos"
            purchasePrice="75000000000"
            second={1600000}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="tuco"
            name="Tuco"
            purchasePrice="1000000000000"
            second={10000000}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="fmi"
            name="FMI"
            purchasePrice="14000000000000"
            second={65000000}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="montoneros"
            name="Montoneros"
            purchasePrice="170000000000"
            second={430000000}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="afip"
            name="AFIP"
            purchasePrice="2000000000000"
            second={2900000000}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
          <Purchase
            image="massa"
            name="Sergio Massa"
            purchasePrice="999999999999999"
            second={-1}
            clicks={clicks}
            setClicks={setClicks}
            dps={dps}
            setDps={setDps}
          />
        </div>
        <div className="clicker">
          <Milei clicks={clicks} setClicks={setClicks} dpc={dpc} />
        </div>
        <div className="right">
          <div className="upgrades">{Upgrades}</div>
          <div className="social">
            <a
              href="https://cafecito.app/mileiclicker"
              rel="noopener"
              target="_blank"
            >
              <img
                srcset="https://cdn.cafecito.app/imgs/buttons/button_1.png 1x, https://cdn.cafecito.app/imgs/buttons/button_1_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_1_3.75x.png 3.75x"
                src="https://cdn.cafecito.app/imgs/buttons/button_1.png"
                alt="Invitame un café en cafecito.app"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
